import { MailIcon, CheckCircleIcon } from "@heroicons/react/outline";
import React, { useEffect, useReducer, useState } from "react";



import {
  validate,
  VALIDATOR_REQUIRE,
  VALIDATOR_EMAIL
} from "../../form-validation/validators";

const loginEmailReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: action.val,
        isValid: validate(action.val, action.validators),
      };
    case "TOUCH":
      return { ...state, isTouched: true };
    default:
      return state;
  }
};


const loginCheckTerms = (state, action) => {
  // console.log(action);
  switch (action.type) {
    case "CHANGE":
      return { ...state, isValid: action.val };
    case "TOUCH":
      return { ...state, isTouched: true };
    default:
      return state;
  }
};

const loginFormReducer = (state, action) => {
  switch (action.type) {
    case "INPUTCHANGE":
      // console.log('We are here')
      let formIsValid = true;
      for (const input in state.inputs) {
        if (input === action.inputId) {
          formIsValid = formIsValid && action.isValid;
        } else {
          formIsValid = formIsValid && state.inputs[input].isValid;
        }
      }
      // console.log(formIsValid)
      return {
        ...state,
        inputs: {
          ...state.inputs,
          [action.inputId]: { value: action.value, isValid: action.isValid },
        },
        isValid: formIsValid,
      };
    default:
      return state;
  }
};

function Subscribe(props) {

  const [page, setPage] = useState(1);
  const [loginEmailState, dispatch] = useReducer(loginEmailReducer, {
    value: "",
    isValid: false,
    isTouched: false,
  });
  // const [messageState, dispatcher] = useReducer(messageReducer, {
  //   value: "",
  //   isValid: false,
  //   isTouched: false,
  // });
  const [checkboxState, dispatchCheckTerms] = useReducer(loginCheckTerms, {
    isValid: false,
    isTouched: false,
  });

  const [loginFormState, dispatching] = useReducer(loginFormReducer, {
    inputs: {
      email: { value: "", isValid: false },
      check: { isValid: false },
    },
    isValid: false,
  }); //here: isValid die gesamte Form


  useEffect(() => {
    // console.log('Yes, running!')
    dispatching({
      type: "INPUTCHANGE",
      value: loginEmailState.value,
      isValid: loginEmailState.isValid,
      inputId: "email",
    });
  }, [loginEmailState.value, loginEmailState.isValid]);

  useEffect(() => {
    // console.log('Yes, running!')
    dispatching({
      type: "INPUTCHANGE",
      isValid: checkboxState.isValid,
      inputId: "check",
    });
  }, [checkboxState.isValid]);



  const emailChangeHandler = (event) => {
    // console.log(event.target.value)
    dispatch({
      type: "CHANGE",
      val: event.target.value,
      validators: [VALIDATOR_REQUIRE(), VALIDATOR_EMAIL()],
    });
  };

  const checkTermsChangeHandler = (event) => {
    dispatchCheckTerms({
      type: "CHANGE",
      val: event.target.checked,
    });
  };

  const emailTouchHandler = () => {
    dispatch({ type: "TOUCH" });
  };
  const checkTermsTouchHandler = () => {
    dispatchCheckTerms({ type: "TOUCH" });
  };


  const loginFormSubmitHandler = async (event) => {
    event.preventDefault();
      setPage(2);
  };

  const redirectToHomepage = () => {
    window.location.href = 'https://migration-check.com';
  }


  return (
    <>

      <div className="min-h-screen flex bg-gray-100">
        <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          {page === 1 ? (<div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <img src="/assets/images/logo.svg" onClick={redirectToHomepage} className="cursor-pointer h-[21px]" alt="Migration-Check Logo"/>
              <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
                Subscribe Now!
              </h2>
              <p className="text-sm text-gray-400 mt-3">
                Here you can subscribe to get our newsletter! Sign up now and stay up-to-date!
              </p>
              <br />
            </div>

            <div>
              <div className="mt-4">
                <form onSubmit={loginFormSubmitHandler} className="space-y-10">
                  {/* Email */}
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-500"
                    >
                      Your Email address
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <MailIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="true"
                        required
                        className="appearance-none block w-full pl-10 px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-rose-500 focus:border-rose-500 sm:text-sm"
                        placeholder="johndoe@gmail.com"
                        onChange={emailChangeHandler}
                        onBlur={emailTouchHandler}
                        value={loginEmailState.value}
                        isInvalid={
                          !loginEmailState.isValid && loginEmailState.isTouched
                        }
                      />
                    </div>
                    {!loginEmailState.isValid && loginEmailState.isTouched && (
                      <p className="mt-1 text-xs text-red-600">
                        Please use a valid email
                      </p>
                    )}
                  </div>




                  {/* Checkbox accept Terms of Use */}
                  <div className="relative flex items-start">
                    <div className="flex items-center h-5">
                      <input
                        id="check"
                        name="check"
                        type="checkbox"
                        className="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded"
                        // checked={checkedTerms}
                        onChange={checkTermsChangeHandler}
                        onBlur={checkTermsTouchHandler}
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label htmlFor="check" className="text-gray-500">
                        By submitting this form, I confirm that I have
                        read and understood the{" "}
                        <a
                          className="text-primary-600 no-underline hover:underline"
                          href="https://migration-check.com/terms/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          terms of use
                        </a>{" "} and the{" "}
                        <a
                          className="text-primary-600 no-underline hover:underline"
                          href="https://migration-check.com/privacy-policy/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          privacy policy
                        </a>{" "}
                        and agree to both of them.
                      </label>
                      <p>
                        {!checkboxState.isValid &&
                          checkboxState.isTouched && (
                            <p className="mt-1 text-xs text-red-600">
                              Please accept our Terms and Conditions to
                              continue!
                            </p>
                          )}
                      </p>
                    </div>
                  </div>


                  <div>
                    <button
                      type="submit"
                      disabled={!loginFormState.isValid}
                      className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500"
                    >
                      Send
                    </button>
                  </div>
                </form>


              </div>
            </div>
            {/* Page 2 */}
          </div>) : (
            <>
              <div className="mx-auto w-full max-w-sm lg:w-96">
                <img className="h-[21px]" src="/assets/images/logo.svg" alt="Migration-Check Logo"/>
                <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
                  Successfully Subscribed!
                </h2>
                <div className="rounded-md bg-green-100 p-4 mt-6">
                <div className="flex">
                  <div className="flex-shrink-0">
                  <CheckCircleIcon
                    className="flex-shrink-0 h-5 w-5 text-green-500"
                    aria-hidden="true"
                  />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-green-800">You have successfully subscibed to our newsletter. Of course you can unsubscribe at any time.</p>
                  </div>
                </div>
              </div>
               
                <br />
                <button
                  type="button"
                  onClick={redirectToHomepage}
                  className="w-full mt-1 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500"
                >
                  Go back to homepage
                </button>
              </div>
            </>
          )}
        </div>
        <div className="hidden lg:block relative w-0 flex-1 bg-linear-primary-3-1 shadow-inner">
          <img
            className="absolute inset-0 h-full w-full object-contain object-bottom"
            src="/assets/images/loginmigrationcheck.png"
            alt="migrationcheck germany"
          />
        </div>
      </div>
    </>
  );
}

export default Subscribe;
